export type TimeslotSettings = {
  hoverBackground?: string;
  activeColor?: string;
  activeBackground?: string;
}

type CompanySettings = {
  showTimezone: boolean;
  showPriceSettings: boolean;
  showServiceDuration: boolean;
  timeslot: TimeslotSettings,
};

const vorontsovskieBaniSettings = {
  showTimezone: false,
  showPriceSettings: false,
  showServiceDuration: false,
  timeslot: {
    hoverBackground: '#eef7ff',
    activeColor: '#ffffff',
    activeBackground: '#3870ff',
  },
} satisfies CompanySettings;

const defaultSettings = {
  showTimezone: true,
  showPriceSettings: true,
  showServiceDuration: true,
  timeslot: {
    hoverBackground: 'unset',
    activeColor: '#3870FF',
    activeBackground: '#ECF6FF',
  },
} satisfies CompanySettings;

function getCustomCompanySettings(clientId?: number) {
  switch (clientId) {
    case 1761:
      return vorontsovskieBaniSettings;
    default: return {};
  }
}

export function getCompanySettings(clientId?: number): CompanySettings {
  const custom = getCustomCompanySettings(clientId);
  return {
    ...defaultSettings,
    ...custom,
  };
}