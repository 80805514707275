import revive_payload_client_eCTCikU6zM from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__u4hzv4maxmno72vaust2hikgzy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GtWGHYENaQ from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__u4hzv4maxmno72vaust2hikgzy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_I2Raebfbjo from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__u4hzv4maxmno72vaust2hikgzy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_o32PVOU619 from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_AVbj1Do8qy from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__u4hzv4maxmno72vaust2hikgzy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BKd5PgYf5T from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__u4hzv4maxmno72vaust2hikgzy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_OwSjmvsZhE from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__u4hzv4maxmno72vaust2hikgzy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cXTxLYQp2u from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__u4hzv4maxmno72vaust2hikgzy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_dsjOC48aSm from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/cue-subclient/.nuxt/components.plugin.mjs";
import prefetch_client_NuWyZNqeNl from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__u4hzv4maxmno72vaust2hikgzy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_7SvU6thrhf from "/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_KZ08TRtMfH from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5_ke6sgeesozspgpif73ntaepqqe/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_kWIEfglE5N from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_isQVfKYxoA from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/app/apps/cue-subclient/.nuxt/unocss.mjs";
import analytics_SVnwK7EMrr from "/app/apps/cue-subclient/plugins/analytics.ts";
import error_handler_kEP5FliEXj from "/app/apps/cue-subclient/plugins/error-handler.ts";
import sentry_3AyO8nEfhE from "/app/apps/cue-subclient/plugins/sentry.ts";
export default [
  revive_payload_client_eCTCikU6zM,
  unhead_GtWGHYENaQ,
  router_I2Raebfbjo,
  _0_siteConfig_o32PVOU619,
  payload_client_AVbj1Do8qy,
  navigation_repaint_client_BKd5PgYf5T,
  check_outdated_build_client_OwSjmvsZhE,
  chunk_reload_client_cXTxLYQp2u,
  plugin_vue3_dsjOC48aSm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NuWyZNqeNl,
  plugin_client_7SvU6thrhf,
  plugin_KZ08TRtMfH,
  switch_locale_path_ssr_kWIEfglE5N,
  i18n_isQVfKYxoA,
  unocss_MzCDxu9LMj,
  analytics_SVnwK7EMrr,
  error_handler_kEP5FliEXj,
  sentry_3AyO8nEfhE
]